import React, { ReactNode, useContext } from "react";
import { RouterOutput, trpc } from "~/components/Utility/trpc";
import { AgencyBillingProvider } from "./agency-billing";

export type AgencyProfile = RouterOutput["agency"]["get"];

interface AgencyContextData {
	agencyProfile?: AgencyProfile;
	isLoading: boolean;
}

export const AgencyContext = React.createContext<AgencyContextData>({
	isLoading: false,
});

const useProvideAgency = () => {
	const { data: agencyProfile, isLoading } = trpc.agency.get.useQuery(
		undefined,
		{
			retry: false,
		},
	);

	return {
		agencyProfile,
		isLoading,
	};
};

export const AgencyProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const value = useProvideAgency();
	return (
		<AgencyContext.Provider value={value}>
			<AgencyBillingProvider>{children}</AgencyBillingProvider>
		</AgencyContext.Provider>
	);
};

export const useAgency = () => useContext(AgencyContext);

export interface RequireAgencyProps {
	children: React.ReactNode;
}
