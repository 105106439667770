import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	ReactNode,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import posthog from "posthog-js";
import { AUTH_TOKEN_KEY, CREATOR_PROFILE_UUID_KEY } from "../api/client";
import { update as updateIntercom } from "../intercom";
import { RouterOutput, trpc } from "~/components/Utility/trpc";
import { Loader } from "~/components/Utility/Loader";

type SessionUser = RouterOutput["user"]["current"];

interface AuthContextData {
	isLoading: boolean;
	user?: SessionUser;
	token?: string;
	setToken(token?: string): void;
	logout(): void;
}

export const AuthContext = React.createContext<AuthContextData>({
	isLoading: true,
	setToken() {
		// Do nothing
	},
	logout() {
		// Do nothing
	},
});

const useProvideAuth = (expectUnauthenticated = false) => {
	const [token, setToken] = useState<string>();

	const queryClient = useQueryClient();
	const { data: user, isLoading } = trpc.user.current.useQuery(
		undefined,
		expectUnauthenticated
			? {
					onError: () => {
						// Do nothing
					},
					retry: false,
					refetchOnWindowFocus: false,
				}
			: undefined,
	);

	const wrappedSetToken = useCallback(
		(token?: string) => {
			if (token) {
				localStorage.setItem(AUTH_TOKEN_KEY, token);
			} else {
				localStorage.removeItem(AUTH_TOKEN_KEY);
				localStorage.removeItem(CREATOR_PROFILE_UUID_KEY);
			}

			setToken(token);
			queryClient.resetQueries();

			try {
				// Stop associating events from this browser with the user
				posthog.reset();
			} catch (error) {
				// Do nothing
			}
		},
		[queryClient],
	);

	useEffect(() => {
		if (typeof window !== "undefined") {
			try {
				wrappedSetToken(localStorage.getItem(AUTH_TOKEN_KEY) ?? undefined);
			} catch (error) {
				// Expected when localStorage is not available (e.g. in some incognito
				// modes)
			}
		}
	}, [wrappedSetToken]);

	const logout = useCallback(
		() => wrappedSetToken(undefined),
		[wrappedSetToken],
	);

	// When the user changes, re-identify them with third parties
	useEffect(() => {
		if (user) {
			posthog.identify(user.uuid);

			updateIntercom({
				email: user.email,
				name: user.firstName + " " + user.lastName,
				created_at: new Date(user.createdAt).getTime() / 1000,
			});
		}
	}, [user]);

	return {
		isLoading,
		user,
		token,
		setToken: wrappedSetToken,
		logout,
	};
};

export const AuthProvider: React.FC<{
	children: ReactNode;
	expectUnauthenticated?: boolean;
}> = ({ children, expectUnauthenticated }) => {
	const value = useProvideAuth(expectUnauthenticated);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export interface RequireAuthProps {
	children: React.ReactNode;
}

/**
 * Shows a spinner until auth has loaded
 */
export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
	const { isLoading } = useAuth();

	if (isLoading) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	return <>{children}</>;
};
