// prettier-ignore
export const load = () => {
	window.intercomSettings = {
		api_base: "https://api-iam.intercom.io",
		app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
		custom_launcher_selector: ".launch_intercom",
	};
	(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+process.env.NEXT_PUBLIC_INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initialize Intercom
export const boot = (userEmail, userEmailHash) => {
	window &&
		window.Intercom &&
		window.Intercom("boot", {
			app_base: "https://api-iam.intercom.io",
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
			email: userEmail,
			user_hash: userEmailHash,
		});
};

export const update = (options = {}) => {
	window &&
		window.Intercom &&
		window.Intercom("update", { ...window.intercomSettings, ...options });
};
