import { CreatorProfile, nonNull } from "@withjuly/fabric";
import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { trpc } from "~/components/Utility/trpc";
import { CREATOR_PROFILE_UUID_KEY } from "../api/client";
import { useAuth } from "./auth";
import { useAgency } from "./agency";
import debounce from "lodash.debounce";

type DebouncedFunc = ReturnType<typeof debounce>;

interface CreatorContextData {
	availableCreatorProfiles: CreatorProfile[];
	creatorProfile?: CreatorProfile;
	setCreatorProfileUuid(creatorProfileUuid?: string): void;
	isImpersonating: boolean;
	setQueuedOperations: (
		update: (
			current: Record<string, DebouncedFunc>,
		) => Record<string, DebouncedFunc>,
	) => void;
}

export const CreatorContext = React.createContext<CreatorContextData>({
	availableCreatorProfiles: [],
	setCreatorProfileUuid() {
		// Do nothing
	},
	isImpersonating: false,
	setQueuedOperations() {
		// Do nothing
	},
});

const useProvideCreator = () => {
	const [creatorProfile, setCreatorProfile] = useState<CreatorProfile>();
	const queuedOperations = useRef<Record<string, DebouncedFunc>>({});

	const { user } = useAuth();
	const { agencyProfile, isLoading: isAgencyLoading } = useAgency();
	const utils = trpc.useContext();
	const { data: managedCreators, isLoading } =
		trpc.creator.getManagedCreators.useQuery(undefined, {
			onError: () => {
				// Do nothing
			},
			retry: false,
			refetchOnWindowFocus: false,
		});

	const availableCreatorProfiles = [
		...(managedCreators ?? []),
		...(agencyProfile?.creators ?? []),
		user?.creatorProfile,
	].filter(nonNull);

	const wrappedSetCreatorProfileUuid = useCallback(
		async (creatorProfileUuid?: string) => {
			if (isLoading || isAgencyLoading) {
				return;
			}

			// Run all queued operations (for example, debouncing mediakit edits)
			// before we switch to the new creator
			//
			// Note that flush will only run if there's a pending operation on the
			// debounced function. If the function has already ran flushing will be a
			// noop.
			if (creatorProfileUuid && creatorProfile?.uuid !== creatorProfileUuid) {
				await Promise.all(
					Object.values(queuedOperations.current).map((q) => q.flush()),
				);
				queuedOperations.current = {};
			}

			if (creatorProfileUuid) {
				let nextCreatorProfile = availableCreatorProfiles.find(
					(cp) => cp.uuid === creatorProfileUuid,
				);
				if (!nextCreatorProfile) {
					nextCreatorProfile = user?.creatorProfile;
				}

				localStorage.setItem(
					CREATOR_PROFILE_UUID_KEY,
					nextCreatorProfile?.uuid ?? "",
				);
				setCreatorProfile(nextCreatorProfile);
			} else {
				// When clearing creator profile, we want to default to the user's
				localStorage.setItem(
					CREATOR_PROFILE_UUID_KEY,
					user?.creatorProfile?.uuid ?? "",
				);
				setCreatorProfile(user?.creatorProfile);
			}
			utils.mediaKit.invalidate();
			utils.dealFlow.invalidate();
			utils.match.invalidate();
			utils.payments.invalidate();
			utils.wishlist.invalidate();
			utils.template.invalidate();
			utils.profile.invalidate();
		},
		[
			availableCreatorProfiles,
			isAgencyLoading,
			isLoading,
			user?.creatorProfile,
			utils,
			creatorProfile?.uuid,
		],
	);

	useEffect(() => {
		if (typeof window !== "undefined") {
			const uuid = localStorage.getItem(CREATOR_PROFILE_UUID_KEY) ?? undefined;
			wrappedSetCreatorProfileUuid(uuid);
		}
	}, [wrappedSetCreatorProfileUuid]);

	const isImpersonating = user?.creatorProfile?.uuid !== creatorProfile?.uuid;

	return {
		availableCreatorProfiles,
		creatorProfile,
		setCreatorProfileUuid: wrappedSetCreatorProfileUuid,
		isImpersonating,
		setQueuedOperations: (
			update: (
				current: Record<string, DebouncedFunc>,
			) => Record<string, DebouncedFunc>,
		) => {
			queuedOperations.current = update(queuedOperations.current);
		},
	};
};

export const CreatorProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const value = useProvideCreator();
	return (
		<CreatorContext.Provider value={value}>{children}</CreatorContext.Provider>
	);
};

export const useCreator = () => useContext(CreatorContext);

export interface RequireCreatorProps {
	children: React.ReactNode;
}
